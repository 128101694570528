import React, { PropsWithChildren, useState, useEffect } from 'react';

import moment from 'moment';

import {
    Theme,
    ThemeProvider,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';

// order is important
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DateRangePicker } from 'react-date-range';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Backdrop from '@material-ui/core/Backdrop';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import { ChartOptions } from 'chart.js';
import { Line } from '@reactchartjs/react-chart.js';

import { Organization, useOrganization } from '../services/Organization';
import {
    Analytics,
    useService as useAnalyticsService,
} from '../services/Analytics';

import TopNav from '../components/TopNav';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import ConfirmActionDialog from '../components/ConfirmActionDialog';

import verificationsIcon from '../img/verifications-icon.png';
import completionsIcon from '../img/completions-icon.png';
import parsesIcon from '../img/parses-icon.png';

import {
    csvIcon as CsvIcon,
    globeIcon as GlobeIcon,
    settingIcon as GearIcon,
} from '../components/Icons';

enum APIType {
    STANDARD = 'standard',
    INTERNATIONAL = 'international',
    BULK = 'bulk',
    INTERNATIONAL_BULK = 'international_bulk',
}

const INFO_DIALOG_SHOWN_VAR = 'infoDialogShown';

const useStyles = makeStyles((theme) => ({
    tabButtonRoot: {
        padding: `${theme.spacing(1)}px ${theme.spacing(6)}px`,
        outline: 'none',
        boxShadow: 'none',
    },
    tabButtonLabel: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: theme.typography.body1.fontFamily,
    },

    bottomProgress: {
        color: '#E5F0FF',
    },
    topProgress: {
        position: 'absolute',
        left: 0,
    },

    accordionRoot: {
        padding: '5px 20px',
        borderRadius: '10px',
        '&.MuiAccordion-root:before': {
            height: 0,
        },
    },

    stepRoot: {
        padding: '8px 0px',
        paddingLeft: '10px',
    },

    dateRange: {
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '10px',
    },

    dateRangeButton: {
        padding: '20px',
        border: '1px #e8e8e8 solid',
        borderRadius: '8px',
    },

    allInclusiveIcon: {
        color: theme.palette.primary.main,
        fontSize: '60px',
    },
}));

const Documentation = () => {
    const classes = useStyles();
    const guides = [
        {
            title: 'Integrate with your Application',
            icon: <GearIcon />,
            steps: [
                {
                    heading: 'Access Developers Page',
                    elaboration:
                        'Navigate to the Developers page on the sidebar',
                },
                {
                    heading: 'Create a Server API Key',
                    elaboration:
                        'Click "Create New Access Key" on the Developers page',
                },
                {
                    heading: 'Read the API Documentation',
                    elaboration:
                        'Access the API Documentation to view code examples and capabilities',
                },
                {
                    heading: 'Test your Integration',
                    elaboration:
                        'Make use of the free lookups to test your integration',
                },
            ],
        },
        {
            title: 'Integrate Autocomplete into your Website',
            icon: <GlobeIcon />,
            steps: [
                {
                    heading: 'Access Developers Page',
                    elaboration:
                        'Navigate to the Developers page on the sidebar',
                },
                {
                    heading: 'Create a Public API Key',
                    elaboration:
                        'Click "Create New Access Key" on the Developers page',
                },
                {
                    heading: 'Follow the Guide',
                    elaboration: 'Expand the API Key to view integration steps',
                },
                {
                    heading: 'Test your Integration',
                    elaboration:
                        'Make use of the free lookups to test your integration',
                },
            ],
        },
        {
            title: 'Bulk Verify your Address List (CSV)',
            icon: <CsvIcon />,
            steps: [
                {
                    heading: 'Access Bulk Verify Page',
                    elaboration:
                        'Nagivate to the Bulk Verify page on the sidebar',
                },
                {
                    heading: 'Upload your File',
                    elaboration: 'Upload your CSV file on the bulk verify page',
                },
                {
                    heading: 'Follow the Wizard',
                    elaboration: 'Follow the steps outlined by the wizard',
                },
                {
                    heading: 'Download your Processed File',
                    elaboration:
                        'Download your file once it is finished processing',
                },
            ],
        },
    ];

    return (
        <Accordion defaultExpanded classes={{ root: classes.accordionRoot }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Integration Guide</Typography>
            </AccordionSummary>
            <ThemeProvider
                theme={(theme: Theme) =>
                    createMuiTheme({
                        ...theme,
                        typography: {
                            ...theme.typography,
                            h6: {
                                ...theme.typography.h6,
                                fontSize: '1rem',
                                fontWeight: 500,
                                textTransform: 'capitalize',
                            },
                            body1: {
                                ...theme.typography.body1,
                                fontSize: '0.9rem',
                            },
                            subtitle1: {
                                ...theme.typography.subtitle1,
                                fontSize: '0.8rem',
                                color: '#6e6e6e',
                            },
                        },
                    })
                }
            >
                <AccordionDetails>
                    <Grid container>
                        {guides.map((guide) => (
                            <Grid item xs>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item>
                                                <Box ml={-1}>{guide.icon}</Box>
                                            </Grid>

                                            <Grid item>
                                                <Typography variant="h6">
                                                    {guide.title}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={2}
                                            direction="column"
                                        >
                                            {guide.steps.map((step, index) => (
                                                <Grid item>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Typography
                                                                style={{
                                                                    color: '#8a97c2',
                                                                }}
                                                            >
                                                                0{index + 1}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                            >
                                                                <Grid item>
                                                                    <Typography>
                                                                        {
                                                                            step.heading
                                                                        }
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item>
                                                                    <Typography variant="subtitle1">
                                                                        {
                                                                            step.elaboration
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </ThemeProvider>
        </Accordion>
    );
};

const TabButton = (
    props: PropsWithChildren<{
        selected?: boolean;
        onClick: () => void;
    }>
) => {
    const classes = useStyles();

    const deselectedStyle = {
        borderColor: '#9b9b9b',
        color: '#9b9b9b',
    };

    return (
        <Button
            classes={{
                root: classes.tabButtonRoot,
                label: classes.tabButtonLabel,
            }}
            color="primary"
            variant="outlined"
            style={props.selected ? undefined : deselectedStyle}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

const CircularProgressWithLabel = (props: { value: number }) => {
    const classes = useStyles();

    return (
        <Box
            position="relative"
            display="flex"
            margin="0 auto"
            justifyContent="center"
        >
            <CircularProgress
                variant="determinate"
                value={100}
                size={65}
                thickness={7}
                className={classes.bottomProgress}
            />
            <CircularProgress
                variant="determinate"
                value={props.value}
                size={65}
                thickness={7}
                className={classes.topProgress}
                style={{
                    transform: 'rotate(-90deg)',
                }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="subtitle1"
                    component="div"
                    color="textPrimary"
                >
                    <b>{props.value.toFixed()}%</b>
                </Typography>
            </Box>
        </Box>
    );
};

const InfoCard = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <Card>
            <CardContent
                style={{ minHeight: 115, paddingLeft: 30, paddingTop: 20 }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    height="100%"
                >
                    {props.children}
                </Box>
            </CardContent>
        </Card>
    );
};

const VerticalDivider = () => {
    return <Box width="1px" height="100%" bgcolor="#e1e1e1"></Box>;
};

const ImageCard = (props: {
    image: string;
    upperLabel: string;
    lowerLabel: string;
}) => {
    return (
        <InfoCard>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <img width={60} src={props.image} />
                </Grid>
                <Grid item>
                    <Box px={2}>
                        <Typography
                            variant="subtitle1"
                            style={{
                                fontSize: 18,
                                lineHeight: 1.6,
                            }}
                        >
                            {props.upperLabel}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14, lineHeight: 1 }}
                        >
                            {props.lowerLabel}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </InfoCard>
    );
};

const AnalyticsChart = (props: { apiType: APIType }) => {
    const service = useAnalyticsService();

    const [analytics, setAnalytics] = useState<Analytics>();
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [error, setError] = useState(false);

    const initDateRange = {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: moment().toDate(),
        key: 'selection',
    };

    const [dateRange, setDateRange] = useState(initDateRange);
    const [previewDateRange, setPreviewDateRange] = useState(initDateRange);

    useEffect(() => {
        (async () => {
            setError(false);
            try {
                setAnalytics(undefined);
                setAnalytics(await service.get(dateRange));
            } catch (err) {
                setError(true);
                console.error(err);
            }
        })();
    }, [service, dateRange]);

    const verificationsColor = '#347ae2';
    const completionsColor = '#fa6457';
    const parsesColor = '#8466f2';

    const data = analytics && {
        labels: analytics.map((v) => v.date),

        datasets: [
            {
                label: 'Verifications',
                data: analytics.map((v) =>
                    props.apiType === APIType.STANDARD
                        ? v.avLookup.verification
                        : props.apiType === APIType.INTERNATIONAL
                        ? v.avLookup.intlVerification
                        : props.apiType === APIType.BULK
                        ? v.avLookup.bulk
                        : v.avLookup.intlBulk
                ),
                fill: false,
                borderColor: verificationsColor,
            },
            {
                label: 'Completions',
                data: analytics.map((v) =>
                    props.apiType === APIType.STANDARD
                        ? v.avLookup.completion
                        : props.apiType === APIType.INTERNATIONAL
                        ? v.avLookup.intlCompletion
                        : 0
                ),
                fill: false,
                borderColor: completionsColor,
            },
            {
                label: 'Parses',
                data: analytics.map((v) =>
                    props.apiType === APIType.STANDARD ? v.avLookup.parse : 0
                ),
                fill: false,
                borderColor: parsesColor,
            },
        ],
    };

    const dateRangeVerifications = data
        ? data.datasets[0].data.reduce((a, v) => a + v, 0)
        : 0;

    const dateRangeCompletions = data
        ? data.datasets[1].data.reduce((a, v) => a + v, 0)
        : 0;

    const dateRangeParses = data
        ? data.datasets[2].data.reduce((a, v) => a + v, 0)
        : 0;

    const dateRangeTotalUsage =
        dateRangeVerifications + dateRangeCompletions + dateRangeParses;

    const classes = useStyles();

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value: number) =>
                            value % 1 === 0 ? value : undefined,
                    },
                },
            ],
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 14,
                    },
                },
            ],
        },
    };

    return (
        <GridPaper direction="column">
            {!analytics && !error ? (
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ minHeight: '50vh' }}
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid item xs={2}>
                        <Box mt={2} />
                    </Grid>

                    <Grid item>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="h5">Analytics</Typography>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    justify="flex-end"
                                >
                                    <Grid item>
                                        <Button
                                            className={classes.dateRangeButton}
                                            onClick={() => {
                                                setShowDateRangePicker(true);
                                            }}
                                            endIcon={<DateRangeIcon />}
                                        >
                                            {moment(dateRange.startDate).format(
                                                'MMMM DD, YYYY'
                                            )}{' '}
                                            -{' '}
                                            {moment(dateRange.endDate).format(
                                                'MMMM DD, YYYY'
                                            )}
                                        </Button>

                                        <Backdrop
                                            open={showDateRangePicker}
                                            style={{ zIndex: 10000 }}
                                        >
                                            {showDateRangePicker && (
                                                <ClickAwayListener
                                                    onClickAway={() => {
                                                        setShowDateRangePicker(
                                                            false
                                                        );
                                                        setDateRange(
                                                            previewDateRange
                                                        );
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        alignItems="center"
                                                    >
                                                        <Grid
                                                            item
                                                            style={{
                                                                margin: '0 auto',
                                                            }}
                                                            className={
                                                                classes.dateRange
                                                            }
                                                        >
                                                            <DateRangePicker
                                                                onChange={(
                                                                    item: any
                                                                ) => {
                                                                    item.selection.endDate =
                                                                        moment(
                                                                            item
                                                                                .selection
                                                                                .endDate
                                                                        )
                                                                            .endOf(
                                                                                'day'
                                                                            )
                                                                            .max(
                                                                                new Date()
                                                                            )
                                                                            .toDate();

                                                                    setPreviewDateRange(
                                                                        item.selection
                                                                    );
                                                                }}
                                                                showSelectionPreview={
                                                                    true
                                                                }
                                                                months={2}
                                                                ranges={[
                                                                    previewDateRange,
                                                                ]}
                                                                direction="horizontal"
                                                                minDate={
                                                                    new Date(
                                                                        2020,
                                                                        9,
                                                                        1
                                                                    )
                                                                }
                                                                maxDate={
                                                                    new Date()
                                                                }
                                                            />
                                                            <Grid
                                                                container
                                                                justify="flex-end"
                                                                spacing={1}
                                                            >
                                                                <Grid item>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            setDateRange(
                                                                                previewDateRange
                                                                            );
                                                                            setShowDateRangePicker(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        Apply
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            setShowDateRangePicker(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ClickAwayListener>
                                            )}
                                        </Backdrop>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            border="1px solid #e3e3e3"
                                            borderRadius="9px"
                                            px={2}
                                            py={1}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        Total Usage
                                                    </Typography>
                                                    <Typography>
                                                        {dateRangeTotalUsage.toLocaleString()}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <VerticalDivider />
                                                </Grid>

                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        Verifications
                                                    </Typography>
                                                    <Typography>
                                                        {dateRangeVerifications.toLocaleString()}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <VerticalDivider />
                                                </Grid>

                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        Completions
                                                    </Typography>
                                                    <Typography>
                                                        {dateRangeCompletions.toLocaleString()}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <VerticalDivider />
                                                </Grid>

                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        Parses
                                                    </Typography>
                                                    <Typography>
                                                        {dateRangeParses.toLocaleString()}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box mt={3} />
                    </Grid>
                    <Grid container item justify="center" spacing={3}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Box
                                        width="15px"
                                        height="9px"
                                        bgcolor={verificationsColor}
                                        borderRadius="2px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Verifications</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Box
                                        width="15px"
                                        height="9px"
                                        bgcolor={completionsColor}
                                        borderRadius="2px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Completions</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Box
                                        width="15px"
                                        height="9px"
                                        bgcolor={parsesColor}
                                        borderRadius="2px"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Parses</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {data && (
                            <Box mt={2} mb={2}>
                                <Line
                                    type="line"
                                    data={data}
                                    options={options}
                                    height={70}
                                />
                            </Box>
                        )}
                    </Grid>
                </>
            )}
        </GridPaper>
    );
};

const Overview = (props: {}) => {
    const org = useOrganization([]);

    const classes = useStyles();

    const [apiType, setAPIType] = useState<APIType>(APIType.STANDARD);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    useEffect(() => {
        setInfoDialogOpen(
            localStorage.getItem(INFO_DIALOG_SHOWN_VAR) !== 'true'
        );
    }, []);

    const totalUsage = (() => {
        if (!org) {
            return 0;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.usage.lookups;

            case APIType.INTERNATIONAL:
                return org.usage.intlLookups;

            case APIType.BULK:
                return org.usage.bulkLookups;

            case APIType.INTERNATIONAL_BULK:
                return org.usage.intlBulkLookups;
        }
    })();

    const totalLimit = (() => {
        if (!org) {
            return 0;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.limit.lookups;

            case APIType.INTERNATIONAL:
                return org.limit.intlLookups;

            case APIType.BULK:
                return org.limit.bulkLookups;

            case APIType.INTERNATIONAL_BULK:
                return org.limit.intlBulkLookups;
        }
    })();

    const verifications = (() => {
        if (!org) {
            return 0;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.usage.verification;

            case APIType.INTERNATIONAL:
                return org.usage.intlVerification;

            case APIType.BULK:
                return org.usage.bulkLookups;

            case APIType.INTERNATIONAL_BULK:
                return org.usage.intlBulkLookups;
        }
    })();

    const completions = (() => {
        if (!org) {
            return 0;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.usage.completion;

            case APIType.INTERNATIONAL:
                return org.usage.intlCompletion;

            case APIType.BULK:
            case APIType.INTERNATIONAL_BULK:
                return 0;
        }
    })();

    const parses = (() => {
        if (!org) {
            return 0;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.usage.parse;

            case APIType.INTERNATIONAL:
            case APIType.BULK:
            case APIType.INTERNATIONAL_BULK:
                return 0;
        }
    })();

    const limitDurationDescriptor = (() => {
        // HACK This uses hardcoded limits to determine whether the user is subscribed
        const total = 'TOTAL';
        const month = moment().format('MMMM').toUpperCase();

        if (!org) {
            return total;
        }

        switch (apiType) {
            case APIType.STANDARD:
                return org.limit.lookups === 500 ? total : month;

            case APIType.INTERNATIONAL:
                return org.limit.intlLookups === 50 ? total : month;

            case APIType.BULK:
            case APIType.INTERNATIONAL_BULK:
                return total;
        }
    })();

    return (
        <>
            <TopNav />

            <ConfirmActionDialog
                // HACK We directly access localStorage but we should probably have a util function for this
                open={infoDialogOpen}
                title="Welcome to PostGrid"
                text={`
                    If this is your first time using PostGrid Verify, we highly recommend taking a look at our guides.
                    You can always access them from our website as well.
                `}
                cancelLabel="Close"
                actionLabel="View Guides"
                confirm={() =>
                    window.open(
                        'https://www.postgrid.com/guides/address-verification/',
                        '_blank'
                    )
                }
                onClose={() => {
                    localStorage.setItem(INFO_DIALOG_SHOWN_VAR, 'true');
                    setInfoDialogOpen(false);
                }}
            />

            <Documentation />

            <Box mt={3} />

            <Grid container spacing={3}>
                <Grid item>
                    <TabButton
                        selected={apiType === APIType.STANDARD}
                        onClick={() => setAPIType(APIType.STANDARD)}
                    >
                        Standard API
                    </TabButton>
                </Grid>
                <Grid item>
                    <TabButton
                        selected={apiType === APIType.INTERNATIONAL}
                        onClick={() => setAPIType(APIType.INTERNATIONAL)}
                    >
                        International API
                    </TabButton>
                </Grid>
                <Grid item>
                    <TabButton
                        selected={apiType === APIType.BULK}
                        onClick={() => setAPIType(APIType.BULK)}
                    >
                        Bulk Verifications
                    </TabButton>
                </Grid>
                <Grid item>
                    <TabButton
                        selected={apiType === APIType.INTERNATIONAL_BULK}
                        onClick={() => setAPIType(APIType.INTERNATIONAL_BULK)}
                    >
                        International Bulk Verifications
                    </TabButton>
                </Grid>
            </Grid>

            <Box mt={2} />
            {org && (
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <InfoCard>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    {totalLimit === -1 ? (
                                        <AllInclusiveIcon
                                            className={classes.allInclusiveIcon}
                                        />
                                    ) : (
                                        <CircularProgressWithLabel
                                            value={
                                                (totalUsage / totalLimit) * 100
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box px={2}>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontSize: 18,
                                                lineHeight: 1.6,
                                            }}
                                        >
                                            {totalLimit === -1
                                                ? `${totalUsage.toLocaleString()}/Unlimited`
                                                : `${totalUsage.toLocaleString()}/${totalLimit.toLocaleString()}`}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontSize: 14,
                                                lineHeight: 1,
                                            }}
                                        >
                                            {limitDurationDescriptor} USAGE
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </InfoCard>
                    </Grid>

                    <Grid item xs={3}>
                        <ImageCard
                            image={verificationsIcon}
                            upperLabel={verifications.toLocaleString()}
                            lowerLabel="VERIFICATIONS"
                        />
                    </Grid>

                    {apiType !== APIType.BULK &&
                        apiType !== APIType.INTERNATIONAL_BULK && (
                            <Grid item xs={3}>
                                <ImageCard
                                    image={completionsIcon}
                                    upperLabel={completions.toLocaleString()}
                                    lowerLabel="COMPLETIONS"
                                />
                            </Grid>
                        )}

                    {apiType === APIType.STANDARD && (
                        <Grid item xs={3}>
                            <ImageCard
                                image={parsesIcon}
                                upperLabel={parses.toLocaleString()}
                                lowerLabel="PARSES"
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            <Box mt={3} />

            <AnalyticsChart apiType={apiType} />
        </>
    );
};

export default Overview;
